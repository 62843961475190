<template>
  <Layout :footer-style="1">
    <div class="container">
      <Breadcrumb root-url="/hardware-product" parent-url="硬件产品" current-url="负载均衡" style="border: none;"/>
    </div>

    <div class="rn-service-area rn-section-gapBottom pt--100 bg-color-light area-2">
      <div class="container">
        <div class="product">
          <img class="pic" src="@/assets/images/product/n5.png" />
          <div class="about">
            <div class="title">负载均衡</div>
            <div class="content">
              负载均衡建立在现有网络结构之上，它提供了一种廉价有效透明的方法扩展网络设备和服务器的带宽、增加吞吐量、加强网络数据处理能力、提高网络的灵活性和可用性。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--60 area-2">
      <div class="container">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">F5</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-25.png">
            <div class="company-info">
              F5 公司是应用交付网络（ADN）领域的全球领先厂商，致力于帮助全球大型的企业和服务提供商实现虚拟化、云计算和“随需应变”的IT的业务价值。F5的解决方案有助于整合不同的技术，以便更好地控制基础架构，提高应用交付和数据管理能力，并使用户能够通过企业桌面系统和智能设备无缝、安全并更快速地接入应用。开放的架构框架使F5客户能够在IT基础架构中的“战略控制点”以及公共云环境中应用业务策略。F5产品为客户提供了所需的灵敏性，使客户能够将IT与不断变化的业务形势保持协调一致，根据需要部署可扩展的解决方案，并对数据与服务的移动访问进行管理。企业、服务提供商和云提供商以及全球领先的在线公司都采用F5的解决方案来优化IT投资，并推动业务发展。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">A10</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-26.png">
            <div class="company-info">
              A10 Networks:网络身份管理的核心。A10公司的旗舰产品Thunder系列应用交付控制器全球有超过2000个客户。A10公司革新技术是它的可扩展性高级核心操作系统（ACOS）Thunder系列具有卓越的可靠性，高性能和能量效率低的总体拥有成本(TCO)。Thunder系列平台为用户提供应用交付器/服务器负载均衡、IPv6迁移、云计算和虚拟化的解决方案。Thunder列为客户提供一个积极的最终用户体验，快速响应业务需求，确保有效的数据中心操作。
            </div>
          </div>
        </div>

        <Separator style="margin: 80px 0;"/>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12">
            <div class="section-title">
              <h3 class="title color-gray">Critix</h3>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <img class="company-logo" src="@/assets/images/brand/brand-27.png">
            <div class="company-info">
              Citrix即美国思杰公司，是一家致力于云计算虚拟化、虚拟桌面和远程接入技术领域的高科技企业。现在流行的BYOD（Bring Your Own Device自带设备办公）就是Citrix公司提出的。1997年Citrix确立的发展愿景“让信息的获取就像打电话一样简洁方便，让任何人在任何时间、任何地方都可以随时获取”，这个构想就是今天移动办公的雏形，随着互联网技术的快速发展，通过基于云计算技术的虚拟桌面，人们可以在任何时间、任何地点使用任何设备接入自己的工作环境，在各种不同的场景间无缝切换，使办公无处不在，轻松易行。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
    .p-img {
      width: 64px;
      height: 64px;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    height: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
